html,
body {
  height: 100%;
  overflow-x: hidden; /* Désactive le défilement horizontal */
  width: 100%; /* S'assure que la largeur est à 100% */
  margin: 0; /* Supprime les marges par défaut */
  padding: 0;
}

.App {
  text-align: center;
  overflow: hidden; /* Empêche le contenu de déborder */
}

.appGlobal {
  background-image: url(assets/background.png);
  background-size: cover; /* Couvre tout l'élément */
  background-position: top left; /* Commence le fond en haut à gauche */
  background-repeat: repeat; /* Répète l'image en X et en Y */
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Assure que l'arrière-plan couvre au moins toute la hauteur de la fenêtre */
}

main {
  flex: 1; /* Permet au main de prendre l'espace restant */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.home {
  width: 100vw;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.footer-bs {
  /* position: absolute; */
  width: 100%;
  left: 0;
  bottom: 0;
  height: 10rem;
}

.uploader-container {
  display: flex;
  flex-direction: column; /* Aligner les éléments verticalement */
  width: 100%;
}

.uploader-container > .vitrail-caroussel {
  width: 50vw;
  margin-bottom: 20px; /* Ajouter un espace entre les éléments */
}

.uploader-container > .review-carousel {
  width: 40vw;
  margin-bottom: 20px; /* Ajouter un espace entre les éléments */
}

.vitrail-caroussel {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 10rem;
  /* color: white; */
  /* background-color: #f163cb; */
}

.vitrail-info {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 10rem;
  /* color: white; */
  text-align: center;
}

.vitrail-caroussel img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}
.review-carousel {
  width: 100vw;
  background-color: #97daee79;
  margin: 0;
  padding: 0 5rem;
  /* color: white; */
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #5a57fa;
  padding: 20px;
  border-radius: 5px;
  max-width: 800px;
  width: 80vw;
  max-height: 80vh;
  overflow-y: auto;
  display: flex; /* Utilise flexbox pour centrer le contenu */
  flex-direction: column; /* Aligne les éléments en colonne */
  align-items: center; /* Centre les éléments horizontalement */
  text-align: center;

  background-image: url(assets/background.png);
  background-size: cover; /* Couvre tout l'élément */
  background-position: top left; /* Commence le fond en haut à gauche */
  background-repeat: repeat;
}

.popup-content img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.form-check {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-check-label {
  margin: 0 2rem;
}

.btn-primary,
.btn-danger,
.btn-warning,
.btn-success,
.btn-secondary {
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .vitrail-caroussel {
    padding: 0 1rem;
    align-items: flex-start;
  }
  /* Agrandir les images du carousel sur mobile */
  .vitrail-caroussel img {
    max-width: 100%; /* Réduit le max-width pour être plus grand sur mobile */
    height: auto;
  }

  .uploader-container > .vitrail-caroussel {
    /*width: 100vw;  Prendre 100% de l'espace du conteneur */
    margin-bottom: 20px; /* Ajouter un espace entre les éléments */
  }

  .uploader-container > .review-carousel {
    width: 100vw; /* Prendre 100% de l'espace du conteneur */
    margin-bottom: 20px; /* Ajouter un espace entre les éléments */
  }

  /* Styles pour centrer le texte dans le popup */
  .popup-content {
    text-align: center; /* Centrer le texte */
    margin: 0;
    padding: 0;
    width: 100vw; /* Prendre 100% de la largeur de l'écran */
  }

  .vitrail-info {
    padding: 0 1rem;
    align-items: flex-start;
  }
}
